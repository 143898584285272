export const state = {
    all_reps: [],

    // selected_rep_id: -1,
    selected_rep_id: -1,
    selected_rep_details: [],
    selected_rep_clients: [],
    selected_rep_client_details: [],
    selectedFiltered_rep_details: null,

    all_clients: [],
};

export const getters = {};

export const actions = {
    //will set the users array
    setRepUsers({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/admin/repusers")
                .then((response) => {
                    commit("setRepUsers", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedRepId({ commit }, id) {
        commit("setSelectedRepId", id);
    },
    //eslint-disable-next-line
    setFilteredSelectedRepDetails({ commit, state }) {
        const user = state.all_reps.find((element) => {
            if (element.id == state.selected_rep_id) {
                return element;
            }
        });
        commit("setFilteredSelectedRepDetails", user);
    },
    //eslint-disable-next-line
    getSelectedRepDetails({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/reps/rep", {
                    params: { rep_user_id: id },
                })
                .then((response) => {
                    commit("setSelectedRepDetails", response.data[0]);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setClients({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/clients/all")
                .then((response) => {
                    commit("setClients", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    createRepToClientLink({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/reps/saveclientlinks", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    setSelectedRepDetails({ commit }, data) {
        commit("setSelectedRepClientDetails", data);
    },

    //eslint-disable-next-line
    createRepClient({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/reps/saveclient", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    createRepUser({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/createuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteRepUser({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/admin/deleteuser", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    setRepUsers(state, payload) {
        state.all_reps = payload;
    },
    setSelectedRepId(state, payload) {
        state.selected_rep_id = payload;
    },
    setSelectedRepDetails(state, payload) {
        state.selected_rep_details = payload;
        state.selected_rep_clients = payload.rep_clients;
    },
    setClients(state, payload) {
        state.all_clients = payload;
    },
    setSelectedRepClientDetails(state, payload) {
        state.selected_rep_client_details = payload;
    },
    setFilteredSelectedRepDetails(state, payload) {
        state.selectedFiltered_rep_details = payload;
    },
};
