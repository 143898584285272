import store from "@/state/store";
//eslint-disable-next-line
import { adminComputed } from "@/state/helpers";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("./views/account/login"),
        /*meta: {
            beforeResolve(routeTo, routeFrom, next) {
                //something wierd is happening here if you logout and log straight back in
                //eslint-disable-next-line
                console.log("over here");

                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },*/
    },
    {
        path: '/forgot-password',
        name: 'forgot',
        component: () => import('./views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ ...routeTo })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password/:token',
        name: 'reset',
        component: () => import('./views/account/reset-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ ...routeTo })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: "/404",
        name: "404",
        component: require("./views/utility/404").default,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: "*",
        redirect: "404",
    },
    {
        path: "/",
        name: "default",
        meta: {
            authRequired: true,
        },
        component: () => import("./views/dashboards/admin/index"),
    },
    {
        path: "/products",
        name: "products",
        meta: { authRequired: true },
        component: () => import("./newviews/products/products"),
    },
    {
        path: "/properties",
        name: "properties",
        meta: { authRequired: true },
        component: () => import("./newviews/products/properties"),
    },
    {
        path: "/product-groups",
        name: "groups",
        meta: { authRequired: true },
        component: () => import("./newviews/products/groups.vue"),
    },
    {
        path: "/product-groups/:groupid",
        name: "groups",
        meta: { authRequired: true },
        component: () => import("./newviews/products/manage-group.vue"),
    },
    {
        path: "/orders",
        name: "orders",
        meta: { authRequired: true },
        component: () => import("./newviews/orders/orders"),
    },
    {
        path: "/create-order",
        name: "orders",
        meta: { authRequired: true },
        component: () => import("./newviews/orders/create-order"),
    },
    {
        path: "/create-order/:clientid",
        name: "orders",
        meta: { authRequired: true },
        component: () => import("./newviews/orders/create-order"),
    },
    // {
    //     path: "/orders/branch/:branchid",
    //     name: "orders",
    //     meta: { authRequired: true },
    //     component: () => import("./newviews/orders/orders"),
    // },
    {
        path: "/users",
        name: "users",
        meta: { authRequired: true },
        component: () => import("./newviews/users/users"),
    },
    //routes for reps and client pages
    {
        path: "/members",
        name: "members",
        meta: { authRequired: true },
        component: () => import("./newviews/clients/clients"),
    },
    {
        path: "/reps",
        name: "reps",
        meta: { authRequired: true },
        component: () => import("./newviews/reps/reps"),
    },
    {
        path: "/reps/view/:repid",
        name: "rep_details",
        meta: { authRequired: true },
        component: () => import("./newviews/reps/repsDetails"),
    },
    {
        path: "/clients/view/:clientid",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/clients/clientDetails"),
    },
    {
        path: "/client/view/order/:orderid",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/clients/clientOrder"),
    },
    //for orders
    {
        path: "/orders/view/order/:orderid",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/orders/orderDetails"),
    },
    //accessed from the reps page
    {
        path: "/reps/view/client/:clientid",
        name: "rep_view_client",
        meta: { authRequired: true },
        component: () => import("./newviews/reps/repClientDetails"),
    },
    {
        path: "/ext/track/order/:token",
        name: "order",
        component: () => import("./newviews/orders/external-order"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("./newviews/crm/external-sales-leads.vue"),
    },
    {
        path: "/complete/membership/:token",
        name: "complete",
        component: () => import("./newviews/crm/membership-completion.vue"),
    },
    {
        path: "/member-requests",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/crm/incoming-member-requests.vue"),
    },
    {
        path: "/marketing",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/crm/marketing.vue"),
    },
    {
        path: "/email-templates",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/crm/email-templates.vue"),
    },
    {
        path: "/mailing-lists",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/crm/mailing-lists.vue"),
    },
    {
        path: "/mailing-lists/:mailinglistid",
        name: "",
        meta: { authRequired: true },
        component: () => import("./newviews/crm/mailing-list.vue"),
    },

    // ! Delete
    // {
    //     path: "/test",
    //     name: "test",
    //     component: () => import("./views/tasks/task-create"),
    // },
];
