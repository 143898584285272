export const state = {
    branch_orders: [],
    order_properties: [],
    selected_order_id: -1,
    selected_order_details: null,
    statsOverview: null,

    ext_order: null, //* order loaded via token
    is_order_readonly: false,

    branch_orders_loading_status: null,
};

export const getters = {};

export const actions = {
    //* PRODUCTS
    getBranchOrders({ commit }, branch_id) {
        return new Promise((resolve, reject) => {
            commit("setBranchOrdersLoadingStatus", true);
            window.axios
                .get("api/orders/all", { params: { branch_id: branch_id } })
                .then((response) => {
                    commit("setOrdersData", response.data);
                    commit("setBranchOrdersLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    getOrderProperties({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/orders/properties")
                .then((response) => {
                    commit("setOrderPropertiesData", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    getOrderDetails({ commit, state }, url = state.selected_order_id) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/orders/order", {
                    params: { id: url },
                })
                .then((response) => {
                    commit("setOrderDetails", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    createOrder({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/orders/create", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    deleteOrder({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/orders/delete", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedOrder({ commit }, order_id) {
        commit("setSelectedOrder", order_id);
    },

    //* External order page
    getTokenOrderDetails({ commit }, token) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/orders/orderbytoken", { params: { token: token } })
                .then((response) => {
                    commit("setTokenOrder", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    // eslint-disable-next-line
    updateOrder({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/orders/update", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // Duplicate
    // eslint-disable-next-line
    // updateOrder({}, requestObject) {
    //     return new Promise((resolve, reject) => {
    //         window.axios
    //             .post("api/orders/update", requestObject)
    //             .then((response) => {
    //                 resolve(response.data);
    //             })
    //             .catch(() => {
    //                 reject();
    //             });
    //     });
    // },

    // eslint-disable-next-line no-unused-vars
    updateExternalOrderStatus({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/orders/updatestatus", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    setOrdersData(state, payload) {
        const { stats, orders } = payload;

        state.branch_orders = orders;
        state.statsOverview = stats;
    },
    setOrderDetails(state, payload) {
        state.is_order_readonly = false;
        state.selected_order_details = payload;
    },
    setTokenOrder(state, payload) {
        state.is_order_readonly = true;
        //* Check if client type is admin to allow the external order to be updated
        if (payload.client.type == "admin") {
            state.is_order_readonly = false;
        }
        state.ext_order = payload;
    },
    setSelectedOrder(state, payload) {
        state.selected_order_id = payload;
    },
    setOrderPropertiesData(state, payload) {
        state.order_properties = payload;
    },
    setBranchOrdersLoadingStatus(state, payload) {
        state.branch_orders_loading_status = payload;
    },
};
