/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-05-08T11:48:33+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-11-08T10:48:52+02:00
 * @Copyright: Dark Roots Collective 2021
 */

export const state = {
    currentUser: localStorage.getItem("x_token"),
};

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        if (newValue == null) {
            window.localStorage.removeItem("x_token");
        }
        state.currentUser = newValue;
        saveState("auth.currentUser", newValue);
    },
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        if (state.currentUser) {
            return true;
        }
        return false;
    },
};

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch("validate");
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch("validate");
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/auth/login", { email: email, password: password })
                .then((response) => {
                    localStorage.setItem("x_token", response.data.access_token);
                    commit("SET_CURRENT_USER", response.data.access_token);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        reject();
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        reject(error.message);
                    }
                });
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit("SET_CURRENT_USER", null);
        // eslint-disable-next-line
        return new Promise((resolve, reject) => {
            resolve();
            // eslint-disable-next-line no-unused-vars
        });
    },

    clearCurrentUser({ commit }) {
        commit("SET_CURRENT_USER", null);
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/auth/check")
                .then((response) => {
                    if (response.data.authenticated) {
                        resolve(true);
                    } else {
                        //* JWT is invalid
                        commit("SET_CURRENT_USER", null);
                        reject(false);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    refresh({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/auth/refresh")
                .then((response) => {
                    localStorage.setItem("x_token", response.data.access_token);
                    commit("SET_CURRENT_USER", response.data.access_token);
                    resolve(response.data.access_token);
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        reject();
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        reject(error.message);
                    }
                });
        });
    },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}
