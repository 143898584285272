import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
//import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from "v-mask";
import VueRouter from "vue-router";
import vco from "v-click-outside";
import router from "./router/index";
import Scrollspy from "vue2-scrollspy";
import VueSweetalert2 from "vue-sweetalert2";
import uploader from "vue-simple-uploader";

import "@/design/index.scss";

import * as webix from "../src/webix-pro";
import "../src/webix-pro/webix.css";
import "../src/webix-pro/skins/material.css";
//* import css for webix action icons
import "@/design/webix_icons.css";

//* import utility css
import "@/design/utility.css";

//imports skotes dark theme (was amerg conflict not sure which one)
// import "../src/design/app-dark.scss";
//import "../src/design/index.scss";

import store from "@/state/store";

import App from "./App.vue";

//import { initFirebaseBackend } from './authUtils'
import i18n from "./i18n";
//import { configureFakeBackend } from './helpers/fake-backend';

import tinymce from "vue-tinymce-editor";
Vue.component("tinymce", tinymce);

import VueBootstrapAutocomplete from "@vue-bootstrap-components/vue-bootstrap-autocomplete";
//import 'bootstrap/scss/bootstrap.scss';
Vue.component("vue-bootstrap-autocomplete", VueBootstrapAutocomplete);

window.axios = require("axios");
window.axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
};
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
window.axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        var token = localStorage.getItem("x_token"); //get token from local storage
        if (token) {
            // inject token
            const AUTH_TOKEN = token;
            config.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

window.webix = webix;
webix.editors.$popup.time = {
    view: "popup",
    body: { view: "calendar", type: "time", icons: true },
};

webix.editors.time = webix.extend(
    {
        popupType: "time",
    },
    webix.editors.date
);

import(`./webix-pro/locales/en-ZA`).then(() => {
    webix.i18n.setLocale("en-ZA");
});

//webix.skin.set("compact");

//webix.skin.contrast.barHeight=46;webix.skin.contrast.tabbarHeight=46;webix.skin.contrast.rowHeight=34;webix.skin.contrast.listItemHeight=34;
//webix.skin.contrast.inputHeight=38;webix.skin.contrast.layoutMargin.wide=10;webix.skin.contrast.layoutMargin.space=10;webix.skin.contrast.layoutPadding.space=10;
webix.skin.set('material');

Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.component("apexchart", VueApexCharts);
Vue.use(uploader);
Vue.prototype.$eventHub = new Vue();

function data_handler(value) {
    //eslint-disable-next-line no-undef
    var view = $$(this.webixId);

    if (typeof value === "object") {
        if (this.copyData) value = webix.copy(value);

        if (view.setValues) view.setValues(value);
        else if (view.parse) {
            view.clearAll();
            view.parse(value);
        }
    } else if (view.setValue) view.setValue(value);

    var subs = view.queryView(function (sub) {
        return sub.hasEvent && sub.hasEvent("onValue");
    }, "all");

    if (subs.length) {
        for (var i = 0; i < subs.length; i++) {
            subs[i].callEvent("onValue", [value]);
        }
    }
}

Vue.component("webix-ui", {
    props: ["config", "value", "copyData"],
    watch: {
        value: {
            handler: data_handler,
        },
    },

    template: "<div></div>",

    mounted: function () {
        var config = webix.copy(this.config);
        config.$scope = this;

        this.webixId = webix.ui(config, this.$el);
        if (this.value) data_handler.call(this, this.value);
    },
    destroyed: function () {
        webix.$$(this.webixId).destructor();
    },
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
