export const state = {
    all_products: [],
    selected_product_id: -1, // -1 default, update to the products id you want to view e.g. 3
    selected_product_details: null,

    all_properties: [],
    selected_property_id: -1,

    all_product_groups: [],
    all_products_groups_loading_status: null,

    all_products_loading_status: null,

    selected_product_group_details: null,

    all_linked_products: [], //from a group, will contain all products
    all_linked_products_loading_status: null,

    all_linked_roles: [],
    active_linked_roles: [],
    role_pricing: [],

    productIds_to_save_to_group: [],
    roleIds_to_save_to_group: [],

    selected_role_for_group_details: [],

    all_display_material_products: [],

    refresh_table_flag: false,

    group_pricing_roles: [],
};

export const getters = {};

export const actions = {
    //* PRODUCTS
    getProducts({ commit }) {
        return new Promise((resolve, reject) => {
            commit("setAllproductLoadingStatus", true);
            window.axios
                .get("api/products/all")
                .then((response) => {
                    commit("setProductsData", response.data);
                    commit("setAllproductLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    getProductDetails({ commit, state }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/products/product", {
                    params: { id: state.selected_product_id },
                })
                .then((response) => {
                    commit("setProductDetails", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    // eslint-disable-next-line no-unused-vars
    saveProduct({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/save", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteProduct({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/delete", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedProduct({ commit }, product_id) {
        commit("setSelectedProduct", product_id);
    },

    //*PROPERTIES
    getProperties({ commit }) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/products/properties")
                .then((response) => {
                    commit("setPropertiesData", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    saveProductProperty({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/saveproperty", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line
    saveProductPropertyValue({}, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/savepropertyvalues", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteProductProperty({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/deleteproperty", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },

    //eslint-disable-next-line
    setAllProductGroups({ commit, state }) {
        return new Promise((resolve, reject) => {
            commit("setAllProductGroupsLoadingStatus", true);
            //eslint-disable-next-line
            console.log(state.all_products_groups_loading_status);
            window.axios
                .get("api/products/groups")
                .then((response) => {
                    commit("setAllProductGroups", response.data);
                    commit("setAllProductGroupsLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    saveProductGroup({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/savegroup", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },

    //eslint-disable-next-line
    setSelctedProductGroupDetails({ commit }, details) {
        commit("setSelctedProductGroupDetails", details);
    },
    //eslint-disable-next-line
    deleteProductGroup({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/deletegroup", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    // addOrRemoveProductsToSaveToGroup({ commit }, details) {
    //     commit("addOrRemoveProductsToSaveToGroup", details);
    // },
    addToProductsToSaveToGroup({ commit }, details) {
        commit("addToProductsToSaveToGroup", details);
    },
    removeFromProductsToSaveToGroup({ commit }, details) {
        commit("removeFromProductsToSaveToGroup", details);
    },
    addRolesToSaveToGroup({ commit }, details) {
        commit("addRolesToSaveToGroup", details);
    },
    removeFromRolesToSaveToGroup({ commit }, details) {
        commit("removeFromRolesToSaveToGroup", details);
    },

    //eslint-disable-next-line
    setAllLinkedProductsByGroup({ commit, state }, requestObject) {
        return new Promise((resolve, reject) => {
            commit("setAllLinkedProductsLoadingStatus", true);
            //eslint-disable-next-line
            console.log(state.all_products_groups_loading_status);
            window.axios
                .get("api/products/group", { params: requestObject })
                .then((response) => {
                    commit("setAllLinkedProductsByGroup", response.data);
                    commit("setSelctedProductGroupDetails", response.data);
                    commit("setAllLinkedProductsLoadingStatus", false);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    setSelectedRoleForGroupDetails({ commit }, product_id) {
        commit("setSelectedRoleForGroupDetails", product_id);
    },
    //eslint-disable-next-line
    saveProductGroupRolePricing({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/savegrouppricing", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },

    //eslint-disable-next-line
    setAllDisplayMaterialProducts({ commit, state }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .get("api/products/alldisplay", { params: requestObject })
                .then((response) => {
                    commit("setAllDisplayMaterialProducts", response.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },
    //eslint-disable-next-line
    deleteGroupPricing({ commit }, requestObject) {
        return new Promise((resolve, reject) => {
            window.axios
                .post("api/products/deletegrouppricing", requestObject)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

export const mutations = {
    setProductsData(state, payload) {
        state.all_products = payload;
    },
    setProductDetails(state, payload) {
        state.selected_product_details = payload;
    },
    setSelectedProduct(state, payload) {
        state.selected_product_id = payload;
    },

    setPropertiesData(state, payload) {
        state.all_properties = payload;
    },
    setAllProductGroups(state, payload) {
        state.all_product_groups = payload;
    },
    setAllproductLoadingStatus(state, payload) {
        state.all_products_loading_status = payload;
    },
    setAllProductGroupsLoadingStatus(state, payload) {
        state.all_products_groups_loading_status = payload;
    },
    setSelctedProductGroupDetails(state, payload) {
        state.selected_product_group_details = payload;
    },
    // addOrRemoveProductsToSaveToGroup(state, payload) {
    //     // state.productIds_to_save_to_group = payload;

    //     const index = state.productIds_to_save_to_group.indexOf(payload);

    //     if (index === -1) {
    //         // If 5 is not in the array, push it
    //         state.productIds_to_save_to_group.push(payload);
    //     } else {
    //         // If 5 is already in the array, remove it
    //         state.productIds_to_save_to_group.splice(index, 1);
    //     }

    //     //eslint-disable-next-line
    //     console.log("state", state.productIds_to_save_to_group);
    // },

    addToProductsToSaveToGroup(state, payload) {
        //eslint-disable-next-line
        // console.log("state to add wa run");
        // //only add if not already in the array
        // const index = state.productIds_to_save_to_group.indexOf(payload);

        // if (index === -1) {
        //     state.productIds_to_save_to_group.push(payload);
        // }
        state.productIds_to_save_to_group.push(payload);
    },
    removeFromProductsToSaveToGroup(state, payload) {
        const index = state.productIds_to_save_to_group.indexOf(payload);

        state.productIds_to_save_to_group.splice(index, 1);
    },
    addRolesToSaveToGroup(state, payload) {
        const index = state.roleIds_to_save_to_group.indexOf(payload);

        if (index === -1) {
            state.roleIds_to_save_to_group.push(payload);
        }
    },
    removeFromRolesToSaveToGroup(state, payload) {
        const index = state.roleIds_to_save_to_group.indexOf(payload);

        state.roleIds_to_save_to_group.splice(index, 1);
    },

    setAllLinkedProductsByGroup(state, payload) {
        state.all_linked_products = null;
        state.all_linked_products = [];
        state.productIds_to_save_to_group = null;
        state.productIds_to_save_to_group = [];

        state.all_linked_roles = null;
        state.all_linked_roles = [];
        state.roleIds_to_save_to_group = null;
        state.roleIds_to_save_to_group = [];

        state.active_linked_roles = null;
        state.active_linked_roles = [];

        state.role_pricing = null;
        state.role_pricing = [];

        state.all_linked_products = payload.all_products;
        state.all_linked_roles = payload.all_roles;
        state.role_pricing = payload.role_pricing;

        state.group_pricing_roles = payload.roles;

        // for (let i = 0; i < state.all_linked_products.length; i++) {
        //     if (state.all_linked_products[i].is_linked == true) {
        //         state.productIds_to_save_to_group.push(state.all_linked_products[i].id);
        //     }
        // }

        for (let i = 0; i < state.all_linked_roles.length; i++) {
            if (state.all_linked_roles[i].is_linked == true) {
                state.roleIds_to_save_to_group.push(state.all_linked_roles[i].id);
                state.active_linked_roles.push(state.all_linked_roles[i]);
            }
        }
        //eslint-disable-next-line
        console.log("what is active linked roles", state.active_linked_roles, state.role_pricing);
        let t = state.group_pricing_roles.map((pricing) => {
            let obj = {
                id: "",
                name: "",
                display_name: "",
                description: "",
                percentile_change: "",
                rule_id: "",
                prices_array: "",
            };
            //eslint-disable-next-line

            if (pricing.prices.length > 0) {
                obj.percentile_change = pricing.prices[0].percentile_change;
                obj.rule_id = pricing.prices[0].id;
            }

            obj.id = pricing.id;
            obj.name = pricing.name;
            obj.display_name = pricing.display_name;
            obj.description = pricing.description;
            obj.prices_array = pricing.prices;

            return obj;
        });

        state.group_pricing_roles = t;
        // state.role_pricing = state.role_pricing.map((role_pricing) => {
        //     let obj = {
        //         id: "",
        //         name: "",
        //         display_name: "",
        //         description: "",
        //         percentile_change: "",
        //     };
        //     //eslint-disable-next-line

        //     for (let role of state.active_linked_roles) {
        //         if (role.id == role_pricing.role_id) {
        //             obj.id = role.id;
        //             obj.name = role.name;
        //             obj.display_name = role.display_name;
        //             obj.description = role.description;
        //             obj.percentile_change = role_pricing.percentile_change;
        //         }
        //     }

        //     return obj;
        // });
        //eslint-disable-next-line
        console.log("role pricing", this.role_pricing);
    },
    setAllLinkedProductsLoadingStatus(state, payload) {
        state.all_linked_products_loading_status = payload;
    },
    setSelectedRoleForGroupDetails(state, payload) {
        state.selected_role_for_group_details = payload;
    },
    setAllDisplayMaterialProducts(state, payload) {
        state.all_display_material_products = payload;
    },
};
