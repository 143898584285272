<script>
import appConfig from "@/app.config";
//eslint-disable-next-line
import store from "@/state/store";

import { notificationMethods, adminMethods, adminComputed } from "@/state/helpers";

export default {
    name: "app",
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },
    computed: {
        ...adminComputed,
    },

    watch: {
        /**
         * Clear the alert message on route change
         */
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            // clear alert on location change
            this.clearNotification();
        },

        core_loaded() {
            if (this.core_loaded == true) {
                this.refreshAuth();
            }
        },
    },
    methods: {
        ...adminMethods,
        clearNotification: notificationMethods.clear,
        refreshAuth() {
            this.auth_timer = setInterval(() => {
                if (store.getters["auth/loggedIn"]) {
                    store.dispatch("auth/refresh").then((validUser) => {
                        if (validUser) {
                            //eslint-disable-next-line
                            console.log("Refreshed User");
                        }
                    });
                }
            }, 300000); //1200000
        },
    },
    mounted() {
        if (window.localStorage.getItem("selected_branch_id")) {
            this.setSelectedBranch(window.localStorage.getItem("selected_branch_id"));
        }
    },
};
</script>

<template>
    <div id="app">
        <RouterView />
    </div>
</template>
